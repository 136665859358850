<template>
  <div class="page-main-bg">
    <v-container class="main-container position-relative">
      <v-toolbar
        height="80px"
        id="page-tollbar"
        class="mt-2 mb-7 mobile__mb__0 narrow-toolbar elevation-0"
      >
        <div class="page-title">
          <!--breadcrumbs-->
          <v-breadcrumbs class="pa-1 pt-3 breadcrumb" :items="breadcrumbs">
            <template v-slot:divider>
              <v-icon class="mt-1">mdi-chevron-left</v-icon>
            </template>
          </v-breadcrumbs>
          <v-toolbar-title class="d-flex align-center">
            <v-toolbar-title
              :style="{ fontSize: '24px' }"
              class="font-weight-bold"
            >
              {{ isCustomer ? 'الرسائل الداخلية' : 'الرسائل' }}
            </v-toolbar-title>
          </v-toolbar-title>
        </div>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-row class="mx-0 my-5" v-if="isMessagingLoad">
        <v-col cols="12" v-for="i in 6" :key="i">
          <v-skeleton-loader
            class="background-transparent"
            width="auto"
            type="list-item-avatar-two-line"
          >
          </v-skeleton-loader>
        </v-col>
      </v-row>
      <template v-else>
        <v-card class="pt-2 mobile__pt__0" v-if="messagingTabs.length">
          <v-tabs
            :class="[
              'messagingTab',
              'mr-4 mobile__mr__0',
              getActiveTabData.tabsActiveTheme
            ]"
            background-color="white"
            center-active
          >
            <v-tab
              @click="setActiveMessageTab(tab.code)"
              class="mx-4 px-2"
              v-for="(tab, i) in messagingTabs"
              :key="i"
            >
              <img
                :src="tab.icon"
                alt="Hector Logo"
                class="mt-n2 ml-2 message__logo__icon"
                loading="lazy"
              />
              <div class="mt-n3">
                <span>{{ tab.title }}</span>
                <span
                  v-if="tab.messageNumber"
                  class="messages-number"
                  :class="[tab.messageCounterColor]"
                >
                  {{ tab.messageNumber }}
                </span>
              </div>
            </v-tab>
          </v-tabs>
        </v-card>

        <template>
          <v-row class="mx-0 my-5" v-if="threadsAreLoading">
            <v-col cols="12" v-for="i in 3" :key="i">
              <v-skeleton-loader
                class="background-transparent"
                width="auto"
                type="list-item-avatar-two-line"
              >
              </v-skeleton-loader>
            </v-col>
          </v-row>
          <!-- Operations Data  -->
          <v-card
            v-else
            class="main-container-card mt-5 message__main__container"
          >
            <div>
              <v-row no-gutters v-if="userThreads.length > 0">
                <v-col v-if="!xs" sm="4" class="message-container">
                  <div
                    class="d-flex justify-space-between align-center pa-3 mx-auto messages-title"
                  >
                    <span :class="messagesTitleClasses">الرسائل</span>
                    <v-btn
                      :class="
                        isCustomer ? 'customer__add__message' : 'purple__color'
                      "
                      fab
                      x-small
                      v-if="getActiveTab !== 'hectar'"
                    >
                      <v-icon
                        @click="toggleNewConversation"
                        :class="getActiveTabData.hectarTabActiveColor"
                      >
                        {{ newConversationIcon }}
                      </v-icon>
                    </v-btn>
                  </div>

                  <div class="threads-container pt-1">
                    <div
                      v-if="
                        addNewConversationClicked && getActiveTab !== 'hectar'
                      "
                      class="pa-2"
                    >
                      <autocomplete
                        :itemData="true"
                        :items="filteredTeamMembers"
                        :noData="noData"
                        :placeholder="selectedRelestatePlaceholder"
                        :validateString="validateString"
                        :returnObject="true"
                        @nextPageTrigger="getNextData"
                        @emitSearchKey="getDataBySearchKey"
                        v-model="teamMember"
                        :loading="clientIsLoading"
                      />
                    </div>

                    <div v-else class="position-relative">
                      <MessageCard
                        v-for="(thread, index) in userThreads"
                        :key="thread._id"
                        :thread-data="thread"
                        :thread-id="threadId"
                        @active-thread="setActiveThread($event, index)"
                        :hectar-tab-active="getActiveTab === 'hectar'"
                      />
                    </div>
                  </div>
                </v-col>

                <v-col sm="8" cols="12" class="conversation-container">
                  <v-icon
                    v-if="xs && expandThreadsIsShown"
                    class="expand-threads"
                    :color="getActiveTabData.iconColor"
                    @click="expandMessages"
                    >mdi-chevron-left</v-icon
                  >
                  <v-row class="mx-0" v-if="conversationMessagesIsLoading">
                    <v-col cols="12" v-for="i in 3" :key="i">
                      <v-skeleton-loader
                        class="background-transparent"
                        width="300"
                        type="list-item-avatar-two-line"
                      >
                      </v-skeleton-loader>
                    </v-col>
                  </v-row>
                  <div v-else class="conversation-body">
                    <ConversationDetails
                      v-if="conversationMessages.length > 0"
                      :hectar-tab-active="getActiveTab === 'hectar'"
                      :messages="conversationMessages"
                      @message-added="addMessageToConversation"
                      @delete-message="deleteMessage"
                    />
                  </div>

                  <div class="xs-messages" v-if="xs && xsThreadsShown">
                    <div class="position-relative xs-messages-container">
                      <div class="pa-3 xs-messages-header">
                        <span class="font-weight-bold">الرسائل</span>
                        <v-icon
                          @click="closeMessages"
                          class="close-expand-messages"
                          color="#737373"
                        >
                          mdi-close
                        </v-icon>
                      </div>

                      <div class="xs-messages-body">
                        <MessageCard
                          v-for="(thread, index) in userThreads"
                          :key="thread._id"
                          :thread-data="thread"
                          :thread-id="threadId"
                          @active-thread="setActiveThread($event, index)"
                          :hectar-tab-active="getActiveTab === 'hectar'"
                        />
                      </div>
                    </div>
                  </div>
                </v-col>
              </v-row>
              <div v-else class="mx-auto">
                <NoMessages
                  :isCustomer="isCustomer"
                  :hectar-tab-active="getActiveTab"
                />
              </div>
            </div>
          </v-card>
          <div
            class="send-message-container"
            v-if="getActiveTab !== 'hectar' && userThreads.length == 0"
          >
            <v-btn
              :class="[
                'add-message-button',
                getActiveTabData.hectarTabActiveBackground
              ]"
              v-if="!isAutocompleteShown"
              @click="showAutocomplete"
            >
              <img :src="sendMessageIcon" loading="lazy" />
              <span class="mr-2 mt-n1">إرسال رسالة</span>
            </v-btn>
            <autocomplete
              :itemData="true"
              :items="filteredTeamMembers"
              :noData="noData"
              :placeholder="selectedRelestatePlaceholder"
              :validateString="validateString"
              :returnObject="true"
              @nextPageTrigger="getNextData"
              @emitSearchKey="getDataBySearchKey"
              v-model="teamMemberXs"
              :loading="clientIsLoading"
              v-else
            />
          </div>
        </template>
      </template>
    </v-container>
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex'
import '@/styles/material-dashboard/_statistics.scss'
import { messageService } from '@/services/messagae.service'
import { EnvService } from '@/services'
import NoMessages from '@/components/messages/NoMessages.vue'
import MessageCard from '@/components/messages/MessageCard.vue'
import ConversationDetails from '@/components/messages/ConversationDetails.vue'
import autocomplete from '@/components/forms/autocomplete'
import { customersService } from '@/services'

export default {
  name: 'Messaging',
  components: {
    NoMessages,
    MessageCard,
    ConversationDetails,
    autocomplete
  },
  data() {
    return {
      isMessagingLoad: true,
      activeMessageTab: 'hectarPlusTeam',
      userThreads: [],
      clientMessageNumber: 0,
      teamMessageNumber: 0,
      hectarMessageNumber: 0,
      breadcrumbs: [
        {
          text: ' الرسائل',
          disabled: true
        }
      ],
      threadId: '',
      expandThreadsIsShown: true,
      xsThreadsShown: false,
      addNewConversationClicked: false,
      sendMessageIcon: require('@/assets/img/svgs/messages/send-message.svg'),
      conversationMessagesIsLoading: false,
      threadsAreLoading: false,
      conversationMessages: [],
      searchKey: '',
      clientIsLoading: false,
      pagination: {
        pageNumber: 1,
        pagesCount: 0,
        pageSize: 10,
        count: 0
      },
      clients: [],
      allClients: [],
      invitedCustomers: [],
      allInvitedCustomers: [],
      selectedRelestatePlaceholder: 'اختر الموظف',
      noData: 'لا يوجد عملاء متاحه لهذا البحث',
      validateString: 'من فضلك ادخل اسم العميل',
      teamMember: {},
      teamMemberXs: {},
      isAutocompleteShown: false,
      teamIds: []
    }
  },
  computed: {
    ...mapState('accountState', ['currentEnv', 'user']),
    ...mapGetters('accountState', ['isCustomer']),
    messagingTabs() {
      return !this.isCustomer
        ? [
            {
              code: 'hectarPlusTeam',
              title: 'رسائل الفريق',
              messageNumber: this.teamMessageNumber,
              icon: require('@/assets/img/svgs/working-active.png')
            },
            {
              code: 'hectar',
              title: 'رسائل هكتار',
              messageNumber: this.hectarMessageNumber,
              icon: require('@/assets/img/svgs/terms_conditions/hectarLogo.svg'),
              messageCounterColor: 'hectar-messages-color'
            },
            {
              code: 'hectarPlusCustomer',
              title: 'رسائل العملاء',
              messageNumber: this.clientMessageNumber,
              icon: require('@/assets/img/svgs/working-active.png')
            }
          ]
        : []
    },
    getActiveTab() {
      return this.activeMessageTab
    },
    xs() {
      return this.$vuetify.breakpoint.xsOnly
    },
    getActiveTabData() {
      switch (this.getActiveTab) {
        case 'hectar':
          return {
            iconColor: '#3dba7e',
            tabsActiveTheme: 'hectar-theme-active',
            hectarTabActiveColor: 'hectar-messages-color',
            hectarTabActiveBackground: 'hectar-messages-background',
            hectarTabActiveBorder: 'hectar-messages-border'
          }
        case 'hectarPlusTeam':
          return {
            iconColor: '#662d91',
            tabsActiveTheme: 'team-theme-active',
            hectarTabActiveColor: 'team-messages-color purple__color',
            hectarTabActiveBackground: 'team-messages-background',
            hectarTabActiveBorder: 'team-messages-border'
          }
        case 'hectarPlusCustomer':
          return {
            iconColor: '#662d00',
            tabsActiveTheme: 'team-theme-active',
            hectarTabActiveColor: 'team-messages-color purple__color',
            hectarTabActiveBackground: 'team-messages-background',
            hectarTabActiveBorder: 'team-messages-border'
          }
        default:
          return {}
      }
    },
    newConversationIcon() {
      return this.addNewConversationClicked ? 'mdi-close' : 'mdi-plus'
    },
    filteredTeamMembers() {
      switch (this.getActiveTab) {
        case 'hectar':
          return this.allClients
        case 'hectarPlusTeam':
          return this.allClients.filter(
            (client) => !this.teamIds.includes(client._id)
          )
        case 'hectarPlusCustomer':
          return this.allInvitedCustomers.filter(
            (client) => !this.teamIds.includes(client._id)
          )
        default:
          return {}
      }
    },
    messagesTitleClasses() {
      return this.isCustomer
        ? 'text--101010 font-weight-medium'
        : 'font-weight-bold'
    }
  },
  watch: {
    teamMember(member) {
      if (Object.keys(member).length) {
        this.addNewConversationClicked = false
        this.addTeamMemberMessage(member)
      }
    },
    teamMemberXs(member) {
      if (Object.keys(member).length) {
        this.isAutocompleteShown = false
        this.addTeamMemberMessage(member)
      }
    }
  },
  methods: {
    ...mapMutations('appState', ['addNotification']),
    setActiveMessageTab(tab) {
      if (this.activeMessageTab !== tab) {
        this.threadsAreLoading = true
        this.conversationMessagesIsLoading = true
        this.activeMessageTab = tab
        this.addNewConversationClicked = false
        this.getUserThreads()
      }
    },
    getUserThreads() {
      messageService
        .getAllThreads(this.user._id, this.activeMessageTab)
        .then((res) => {
          this.userThreads = res.data.threads
          if (this.getActiveTab === 'hectar') {
            this.hectarMessageNumber = this.userThreads.length
          } else if (this.getActiveTab === 'hectarPlusCustomer') {
            this.clientMessageNumber = this.userThreads.length
            this.teamIds = this.userThreads.map((thread) => {
              if (thread.senderId === this.user._id) {
                return thread.receiverId
              }
              return thread.senderId
            })
          } else {
            this.teamMessageNumber = this.userThreads.length
            this.teamIds = this.userThreads.map((thread) => {
              if (thread.senderId === this.user._id) {
                return thread.receiverId
              }
              return thread.senderId
            })
          }
          if (this.userThreads[0]) {
            this.threadId = this.userThreads[0]._id
            this.setAsSeen(this.userThreads[0], 0)
            this.getConversationByThread(this.threadId)
          } else {
            this.conversationMessages = []
          }
        })
        .finally(() => {
          this.threadsAreLoading = false
          this.conversationMessagesIsLoading = false
          this.isMessagingLoad = false
        })
    },
    getConversationByThread(threadId) {
      this.conversationMessagesIsLoading = true
      messageService
        .getMessages(threadId)
        .then((res) => {
          this.conversationMessages = res.data.items
        })
        .finally(() => {
          this.conversationMessagesIsLoading = false
        })
    },
    setAsSeen(thread, threadIndex) {
      if (!thread.seen && thread.receiverId === this.user._id) {
        return messageService.setMessageSeen(thread._id).then(() => {
          this.$set(this.userThreads[threadIndex], 'seen', true)
        })
      }
      return null
    },
    setActiveThread(thread, threadIndex) {
      this.threadId = thread._id
      this.setAsSeen(thread, threadIndex)
      this.getConversationByThread(this.threadId)
    },
    expandMessages() {
      this.expandThreadsIsShown = false
      this.xsThreadsShown = true
    },
    closeMessages() {
      this.expandThreadsIsShown = true
      this.xsThreadsShown = false
    },
    toggleNewConversation() {
      this.addNewConversationClicked = !this.addNewConversationClicked
    },
    addMessageToConversation(message) {
      this.conversationMessages.push(message)
    },
    async deleteMessage(threadId) {
      this.conversationMessagesIsLoading = true
      await messageService.deleteThread(threadId)
      this.teamMemberXs = {}
      this.teamMember = {}
      this.getUserThreads()
    },
    getDataBySearchKey(e) {
      this.searchKey = e
      if (this.searchKey) {
        this.pagination.pageNumber = 1
        if (this.getActiveTab === 'hectarPlusTeam') {
          this.loadClients()
        } else {
          this.loadCustomer()
        }
        this.clientIsLoading = true
      } else {
        this.clientIsLoading = false
      }
    },
    getNextData() {
      if (this.pagination.pageNumber < this.pagination.pagesCount) {
        this.pagination.pageNumber += 1
        this.clientIsLoading =
          this.getActiveTab === 'hectarPlusTeam'
            ? this.loadClients()
            : this.loadCustomer()
      }
    },
    loadClients() {
      let data = `&pageSize=${this.pagination.pageSize}&pageNumber=${this.pagination.pageNumber}&registered=1`
      if (this.searchKey) {
        data += `&search=${this.searchKey}`
      }
      console.log('clients', data)
      EnvService.getAllEmployee(this.currentEnv._id, data)
        .then((res) => {
          if (res.data && res.data.users) {
            this.pagination.pagesCount = Math.ceil(
              res?.data?.count / this.pagination.pageSize
            )
            this.clients = res?.data?.users ?? []
            this.pagination.count = res?.data?.count ?? 0
            this.allClients = [...this.allClients, ...this.clients]
            const userIndex = this.allClients.findIndex(
              (client) => client._id === this.user._id
            )
            if (userIndex !== -1) {
              this.allClients.splice(userIndex, 1)
            }
          }
        })
        .catch(() => {
          this.addNotification({
            text: 'حدث خطأ اثناء تحميل اعضاء الفريق',
            color: 'error'
          })
        })
        .finally(() => {
          this.clientIsLoading = false
        })
    },
    async loadCustomer() {
      let data = `&pageSize=${this.pagination.pageSize}&pageNumber=${this.pagination.pageNumber}&registered=1`
      if (this.searchKey) {
        data += `&search=${this.searchKey}`
      }
      try {
        const response = await EnvService.getAllEmployee(
          this.currentEnv._id,
          data
        )
        this.pagination.pagesCount = Math.ceil(
          response?.data?.count / this.pagination.pageSize
        )
        this.pagination.count = response?.data?.count ?? 0
        this.invitedCustomers = response.data.users ?? []
        this.allInvitedCustomers = [
          ...this.allInvitedCustomers,
          ...this.invitedCustomers
        ]
      } catch {
        this.addNotification({
          text: 'حدث خطأ اثناء تحميل العملاء المدعويين',
          color: 'error'
        })
      }
    },
    showAutocomplete() {
      this.isAutocompleteShown = true
    },
    removeTeamMember(memberId) {
      const memberIndex = this.filteredTeamMembers.findIndex(
        (member) => member._id === memberId
      )
      if (memberIndex !== -1) {
        this.filteredTeamMembers.splice(memberIndex, 1)
      }
    },
    addTeamMemberMessage(member) {
      const messageBody = {
        content: `الأن يمكنك التواصل مع  ${member.name}`,
        sender: {
          _id: this.user._id,
          name: this.user.name,
          phoneNumber: this.user.phoneNumber,
          email: this.user.email
        },
        receiver: {
          _id: member._id,
          name: member.name,
          phoneNumber: member.phoneNumber
        },
        context: {
          platform: this.getActiveTab
        },
        subjectId: member._id
      }
      if (member.pushToken) {
        messageBody.pushToken = member.pushToken
      }
      messageService.addNewMessage(messageBody).then((res) => {
        this.conversationMessagesIsLoading = true
        this.threadId = res.data.thread
        messageService
          .getAllThreads(this.user._id, this.activeMessageTab)
          .then((response) => {
            this.userThreads = response.data.threads
            if (this.getActiveTab === 'hectar') {
              this.hectarMessageNumber = this.userThreads.length
            } else if (this.getActiveTab === 'hectarPlusCustomer') {
              this.clientMessageNumber = this.userThreads.length
              this.teamIds = this.userThreads.map((thread) => {
                if (thread.senderId === this.user._id) {
                  return thread.receiverId
                }
                return thread.senderId
              })
            } else {
              this.teamMessageNumber = this.userThreads.length
              this.teamIds = this.userThreads.map((thread) => {
                if (thread.senderId === this.user._id) {
                  return thread.receiverId
                }
                return thread.senderId
              })
            }
            this.removeTeamMember(member._id)
          })
          .finally(() => {
            this.conversationMessagesIsLoading = false
          })
        this.getConversationByThread(this.threadId)
      })
    }
  },
  created() {
    this.isCustomer && (this.activeMessageTab = 'hectarPlusCustomer')
    this.getUserThreads()
    this.loadClients()
    this.loadCustomer()
  }
}
</script>

<style lang="scss">
@import '@/styles/responsiveness/responsiveness.scss';
@import '/src/styles/material-dashboard/_variables.scss';
.messagingTab {
  .v-tab {
    font-size: 16px;
    color: #101010 !important;
  }
  .v-item-group {
    @include max-sm {
      .v-slide-group__prev--disabled,
      .v-slide-group__next--disabled {
        display: none !important;
      }
    }
    // @include max-sm{}
  }

  .v-tabs-slider {
    width: 100% !important;
    border-radius: 2px !important;
    height: 2px;
  }

  .messages-number {
    margin-right: 4px;
    padding: 0 4px;
    border: 1px solid #efebf2;
    border-radius: 20px;
    background: rgba($primary-color, 0.05);
    font-size: 13px;
  }
}

.hectar-theme-active {
  .v-tab--active {
    color: #3dba7e !important;
  }
  .v-tabs-slider {
    color: #3dba7e !important;
  }
}

.team-theme-active {
  .v-tab--active {
    color: $primary-color !important;
  }
  .v-tabs-slider {
    color: $primary-color !important;
  }
}

.v-breadcrumbs li {
  font-size: 12px;
}

.v-breadcrumbs li:nth-child(2n) {
  padding: 0 8px;

  img {
    height: 10px;
    width: 10px;
  }
}

.message-container {
  border-left: 2px solid #f3f3f3;

  .messages-title {
    box-shadow: 0px 7px 30px rgb(0 0 0 / 6%);
    height: 48px;

    // width: 95%;
    .v-btn--is-elevated {
      box-shadow: none;
    }
  }

  .threads-container {
    max-height: 60vh;
    overflow-y: scroll;
  }
}

.conversation-container {
  position: relative;

  .conversation-body {
    height: 100%;
  }

  .expand-threads {
    position: absolute;
    top: 9px;
    right: 0;
    z-index: 99;
    cursor: pointer;
  }

  .xs-messages {
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 999;
    background-color: white;
    box-shadow: -7px 5px 10px -2px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    width: 264px;

    .xs-messages-container {
      height: 100%;

      .xs-messages-header {
        display: flex;
        height: 48px;
        align-items: center;
        justify-content: space-between;

        .close-expand-messages {
          cursor: pointer;
        }
      }

      .xs-messages-body {
        max-height: 75%;
        overflow-y: scroll;
      }
    }
  }
}

.send-message-container {
  position: fixed;
  bottom: 1px;
  left: 30px;

  .add-message-button {
    border: 2px solid #efebf2 !important;
    border-radius: 15px !important;
    color: white;
    height: 40px !important;
    background-color: $primary-color !important;
  }

  .v-btn--is-elevated {
    box-shadow: none;
  }
}

.v-card.main-container-card.message__main__container {
  border-radius: 10px !important;

  .v-btn--fab.v-size--x-small {
    height: 24px;
    width: 24px;
    padding: 0 10px;
    background: #ecf8f2;

    &.customer__add__message {
      background-color: rgba(102, 45, 145, 0.1) !important;
    }

    &:hover {
      background: #3dba7e !important;

      &.purple__color {
        background: rgba($primary-color, 0.1) !important;

        &:hover {
          background: $primary-color !important;

          .v-btn__content {
            .team-messages-color {
              color: #ecf8f2 !important;
            }
          }
        }
      }

      &.customer__add__message {
        background-color: rgba(102, 45, 145, 0.1) !important;
      }

      .v-btn__content {
        .team-messages-color {
          color: #ecf8f2 !important;
        }
      }
    }

    .v-btn__content {
      .team-messages-color {
        color: #3dba7e !important;

        &.purple__color {
          color: $primary-color !important;
        }
      }
    }
  }
}
.message__logo__icon {
  height: 24px;
  width: 24px;
  object-fit: contain;
}
</style>
